.list-sort-demo-wrapper {
  position: relative;
  background: #e6e6e6;
  overflow: hidden;
}

.list-sort-demo {
  margin: 40px auto;
  max-width: 900px;
  width: 90%;
  position: relative;
  cursor: hand;
}
.list-sort-demo > div{
  overflow: hidden;
}

.list-sort-demo-list {
  background: #fff;
  border-radius: 6px;
  margin: 5px auto;
  padding: 10px;
  margin-bottom: 10px;
  transition:  box-shadow .5s, transform .5s;
}

.list-sort-demo-list.list-drag-selected{
  box-shadow: 0 8px 20px #E6E6E6;
  transform: scale(1.1) !important;
}

.list-sort-demo-icon {
  width: 20%;
  display: inline-block;
  text-align: center;
  font-size: 24px;
  line-height: 50px;
  vertical-align: top;
}
.list-sort-demo-text{
  width: 80%;
  display: inline-block;
}

.list-sort-demo-text h1 {
  font-size: 18px;
}

.list-sort-demo-text p{
  font-size: 12px;
}

@media screen and (max-width: 320px) {
  .list-sort-demo-text h1 {
    font-size: 14px;
    line-height: 28px;
  }
}
